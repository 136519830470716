[class*="col-"] {
  width: 100%;
}
  /*
@media only screen {
For tablets:
  .col-s-1 {width: 100%;}
  .col-s-2 {width: 100%;}
  .col-s-3 {width: 100%;}
  .col-s-4 {width: 100%;}
  .col-s-5 {width: 100%;}
  .col-s-6 {width: 100%;}
  .col-s-7 {width: 100%;}
  .col-s-8 {width: 100%;}
  .col-s-9 {width: 100%;}
  .col-s-10 {width: 100%;}
  .col-s-11 {width: 100%;}
  .col-s-12 {width: 100%;}
}
 */

@media only screen (max-width: 820px) {
  /* For tablets: */
  .col-s-1 {width: 100%;}
  .col-s-2 {width: 100%;}
  .col-s-3 {width: 100%;}
  .col-s-4 {width: 100%;}
  .col-s-5 {width: 100%;}
  .col-s-6 {width: 100%;}
  .col-s-7 {width: 100%;}
  .col-s-8 {width: 100%;}
  .col-s-9 {width: 100%;}
  .col-s-10 {width: 100%;}
  .col-s-11 {width: 100%;}
  .col-s-12 {width: 100%;}
  .mob-text {font-weight: 200;
              font-size: '1rem';}
}
@media only screen and (min-width: 821px) {
  /* For desktop: */
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 45.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
  .mob-text {font-weight: 500;
              font-size: '1.5rem';}
}

:root {
  --brandColor: #88154f;
  --darkBrandColor: #150B47;
  --lightBrandColor: #e4e4f2;
  --whiteColor: #ffffff;
  --blackColor: #000000;
}
.app-bar {
  background: #0000FF
}
.navbarCustom .menu-link{
  margin-top:2px;
  font-weight:600;
  font-size: 2rem;
  color: var(--blackColor);
  display: block;
}

.navbar .menu-link:hover{
  color: var(--brandColor);
}
.typed-cursor{
  opacity: 1;
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
}
@keyframes typedjsBlink{
  50% { opacity: 0.0; }
}
@-webkit-keyframes typedjsBlink{
  0% { opacity: 1; }
  50% { opacity: 0.0; }
  100% { opacity: 1; }
}
.typed-fade-out{
  opacity: 0;
  transition: opacity .25s;
  -webkit-animation: 0;
  animation: 0;
}
.rounded-image {
  border-radius: 10px; /* Adjust the value to change the roundness */
  width: 200px; /* Set the width of the image */
  height: auto; /* Maintain aspect ratio */
}